<template>
  <v-container>
    <v-row class="mt-10">
      <v-col align="center">
        <span>Authentication Complete! You Can Close This Window.</span>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { onMounted } from '@vue/composition-api'

export default {
  props: {
    urlProps: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    onMounted(() => {
      // Get Token Settings For Google
      if (props.urlProps.fullPath.includes('google')) {
        localStorage.setItem('tempAuth', JSON.stringify(props.urlProps.query))
      }

      window.close()
    })

    return {}
  },
}
</script>
